import { useState, useEffect } from 'react'

export const useResponsive = (breakpoints) => {
  const sizes = Object.entries(breakpoints).sort(([a, aSize], [b, bSize]) => bSize - aSize)
  if (sizes[sizes.length - 1][1] !== 0) {
    console.warn('fixing', sizes[sizes.length - 1][0], 'size which should be 0')
    sizes[sizes.length - 1][1] = 0
  }

  const getWindowInnerSize = (window = { innerWidth: 1024, innerHeight: 1024 }) => {
    const { innerWidth, innerHeight } = window;
    return {
      width: innerWidth,
      height: innerHeight
    }
  }

  const getScreen = () => {
    const { width, height } = getWindowInnerSize()
    const size = sizes.find(([_, size]) => size < width)[0]
    const orientation = width > height ? 'landscape' : 'portrait'
    return {
      size,
      orientation,
      screenIsAtLeast(breakpoint, andOrientation) {
        return width >= breakpoints[breakpoint] && (!andOrientation || andOrientation === orientation)
      },
      screenIsAtMost(breakpoint, andOrientation) {
        return width <= breakpoints[breakpoint] && (!andOrientation || andOrientation === orientation)
      }
    }
  }

  const [screen, setScreen] = useState(getScreen())

  useEffect(
    () => {
      const onResize = () => {
        const current = getScreen()

        if (current.size !== screen.size || current.orientation !== screen.orientation) {
          setScreen(current)
        }
      }
      if (window) {
        window.addEventListener('resize', onResize)
      }

      return () => {
        if (window) {
          window.removeEventListener('resize', onResize)
        }
      }
    },
    [screen, setScreen, getScreen]
  )

  return screen
}