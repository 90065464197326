import React from "react"

const HeaderBanner = ({ title, backgroundImage, backgroundColor }) => (
  <section
    className="c-hosting-lite-banner"
    style={{
      backgroundImage: `url(${backgroundImage})`,
      backgroundColor
    }}
  >
    <section className="wrapper">
      <section className="c-hosting-lite-banner__container">
        <div className="c-hosting-lite-banner__info">
          <h3 className="c-hosting-lite-banner__title">{title}</h3>
        </div>
      </section>
    </section>
  </section>
)

export default HeaderBanner
