import React, { useState } from "react"
import Collapsible from 'react-collapsible';
import { useResponsive } from '../hooks/useResponsive';

const splitArray = (items, numCols) => {
  return items.reduce((newArray, item, i) => {
    newArray[i % numCols].push(item);
    return newArray;
  }, [...Array(numCols)].map(_ => []));
}

const mapAccordionData = (data, openIndex = 0) => {
  return data.map((item, index) => {
    return {
      ...item,
      key: `${item.label}-${index}`,
      isOpen: index === openIndex
    };
  });
}

const breakpoints = { xs: 0, sm: 640, md: 1024 }

const AccordionHeader = ({ label, isOpen = false }) => {
  return (
    <div
      href="javascript:void(0);"
      className="c-accordion__header"
    >
      <span className="c-accordion__label">{label}</span>
      <button
        className={`c-accordion__button ${isOpen ? "c-accordion__button--open" : "c-accordion__button--closed"
          }`}
      >
        {isOpen ? "-" : "+"}
      </button>
    </div >
  )
}


const AccordionPanel = ({ itemsData, transitionTime = 300, numCols = 2 }) => {
  const { screenIsAtLeast } = useResponsive(breakpoints)
  const [items, setItems] = useState(mapAccordionData(itemsData));

  if (!itemsData) return <p>{"No Data"}</p>;

  const toggleOpen = (triggeredKey) => {
    setItems(items.map(item => {
      return {
        ...item,
        isOpen: item.key === triggeredKey ? !item.isOpen : item.isOpen,
      }
    }));
  }

  const columns = screenIsAtLeast('sm') ? splitArray(items, numCols) : [items];

  return (
    <div className="c-accordion-global">
      <div className="wrapper">
        <h2 className="c-accordion-global__title">
          Preguntas frecuentes (f.a.q.)
        </h2>
        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
          {
            columns.map((colItems, i) => {
              return (
                <div className="c-accordion-panel" key={i}>
                  {
                    colItems.map(({ label, content, isOpen, key }, i) => {
                      return (
                        <div className="c-accordion" key={key}>
                          <Collapsible
                            trigger={AccordionHeader({ label })}
                            triggerWhenOpen={AccordionHeader({ label, isOpen: true })}
                            handleTriggerClick={() => toggleOpen(key)}
                            transitionTime={transitionTime}
                            open={isOpen}
                          >
                            <div className="c-accordion__body">
                              {content}
                            </div>
                          </Collapsible>
                        </div>
                      )
                    })
                  }
                </div>
              );
            })
          }
        </div>
      </div>
    </div >
  )
}

export default AccordionPanel
