import React, { useState } from "react"

const PlanPricing = ({ plan, showMonthlyPrice }) => {
  const { name, url, monthlyPrice, yearlyPrice, ...planSpecs } = plan;

  const specRender = ({ spec, value }) => {
    const specMap = {
      domains: value => value === 'unlimited' ? 'Dominios ilimitados' : `Hasta ${value} dominios`,
      accounts: value => `Hasta ${value} cuentas`,
      disk: value => `${value} de disco`,
      transfer: value => value === 'unlimited' ? 'Transferencia no medida' : `${value} de transferencia`,
      ram: value => `${value} de RAM`,
      default: value => value,
    }
    return (specMap[spec] || specMap.default)(value);
  }

  return (
    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3">
      <div className="c-hosting-lite-prices__card">
        <div className="c-hosting-lite-prices__card-header">
          <p>{name}</p>
          <span> {showMonthlyPrice ? `${monthlyPrice}/mes` : `${yearlyPrice}/año `}</span>
        </div>

        <div className="c-hosting-lite-prices__card-body">
          <ul>
            {
              Object.entries(planSpecs)
                .filter(([spec, value]) => value)
                .map(([spec, value]) => {
                  return (
                    <li key={spec}>
                      <img
                        src="https://i.ya-webdesign.com/images/transparent-check-lime-green-3.png"
                        alt={spec}
                      />
                      {specRender({ spec, value })}
                    </li>
                  );
                })
            }
          </ul>
        </div>

        <div className="c-hosting-lite-prices__card-footer">
          <button className="btn-primary">Contrátalo ya</button>
          <span>Ver mas características</span>
        </div>
      </div>
    </div>
  );
}

const PricingTable = ({ title, footerText, plans }) => {
  const [showMonthlyPrice, setShowMonthlyPrice] = useState(true);

  return (
    <section className="c-hosting-lite-prices --lightgreen">
      <section className="wrapper">
        <section className="c-hosting-lite-prices__container">
          <h1 className="c-hosting-lite-prices__title">
            {title}
          </h1>
          <div className="c-hosting-lite-prices__content">
            <div className="row">
              {
                plans.map(plan => {
                  return (
                    <PlanPricing
                      plan={plan}
                      key={plan.name}
                      showMonthlyPrice={showMonthlyPrice}
                    />
                  )
                })
              }
            </div>
            <div className="c-hosting-lite-prices__selector-time">
              <button
                className={`${showMonthlyPrice ? 'is-active' : ''}`}
                onClick={() => setShowMonthlyPrice(true)}
              >
                <span>Pago Mensual</span>
              </button>
              <button
                className={`${!showMonthlyPrice ? 'is-active' : ''}`}
                onClick={() => setShowMonthlyPrice(false)}
              >
                <span>Pago Anual</span>
                <span>(1 mes gratis)</span>
              </button>
            </div>
          </div>
          <div className="c-hosting-lite-prices__description">
            {footerText.map((text, i) => <p key={i}>{text}</p>)}
          </div>
        </section>
      </section>
    </section>
  )
}

export default PricingTable
