import React from "react"

const ServiceDescription = ({ title, texts }) => (
  <>
    <section className="c-hosting-lite">
      <section className="wrapper">
        <section className="c-hosting-lite__container">
          <h1 className="c-hosting-lite__title">{title}</h1>
          {texts.map((text, i) => <p key={i} className="c-hosting-lite__description">{text}</p>)}
        </section>
      </section>
    </section>
  </>
)

export default ServiceDescription
