import React from "react"

import { graphql } from "gatsby"
import Layout from "../components/layout/layout";
import SEO from "../components/seo";
import ServiceDescription from "../components/service-description"
import HeaderBanner from "../components/header-banner"
import PricingTable from "../components/pricing-table"
import Call from "../components/call"
import FAQAccordion from '../components/accordion'

const HostingTemplate = ({ data }) => {
  const {
    title,
    banner,
    plans,
    pricingText,
    description,
    faqs
  } = data.hostingYaml;

  return (
    <Layout>
      <SEO title={title} />
      <HeaderBanner
        title={banner.title}
        backgroundImage={banner.backgroundImage}
        backgroundColor={banner.backgroundColor}
      />
      <PricingTable title={pricingText.title} footerText={pricingText.footer} plans={plans} />
      <ServiceDescription title={description.title} texts={description.content} />
      <FAQAccordion itemsData={faqs} />
      <Call />
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    hostingYaml(fields: {slug: {eq: $slug}}) {      
      fields {
        slug
      }
      title
      banner {
        title
        backgroundImage
        backgroundColor
      }
      plans {
        name
        monthlyPrice
        yearlyPrice
        domains
        accounts
        disk
        transfer
        ram
        cpu
        ip
        ssl
        url
      }
      pricingText {
        title
        footer
      }
      description {
        title
        content
      }
      faqs {
        label
        content
      }
    }
  }
`
export default HostingTemplate