import React from "react"

const Call = () => (
  <section className="c-call">
    <section className="wrapper">
      <section className="c-call__container row">
        <div className="col-xs--12 col-sm-7 col-md-8">
          <p className="c-call__text">¿Tienes alguna duda o consulta? Estamos para ayudarte.</p>
        </div>
        <div className="col-xs--12 col-sm-5 col-md-4">
          <button className="btn-primary c-call__btn">Te llamamos gratis</button>
        </div>
      </section>
    </section>
  </section>
)

export default Call
